import React, {useState} from "react";
import { ChakraProvider } from "@chakra-ui/react";

import Header from "./components/Header";
import Nav from "./components/Nav";
import theme from "./theme";
import PdfViewerDialog from "./components/PdfDialog";

const App = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const stars = () => {
    const row = [];
    for (let i = 0; i < 20; i++) {
      row.push(<li key={i} />);
    }
    return row;
  };

  return (
    <>
      <ChakraProvider theme={theme}>
        <div className="context">
          <div className="area">
            <ul className="circles">{stars()}</ul>
          </div>
        </div>
        <Nav />
        <Header setOpen={setOpen}/>
      </ChakraProvider>
      <PdfViewerDialog open={open} handleClose={handleClose} />
  </>
  );
};

export default App;
