import {
  Box,
  Flex,
  Heading,
  Container,
  Stack,
  Text,
  Link,
  Icon,
  useColorModeValue,
  Badge,
  Img,
  Tooltip,
} from "@chakra-ui/react";
import { FiGithub, FiLinkedin } from "react-icons/fi";
import {
  FaCss3,
  FaHtml5,
  FaJs,
  FaNodeJs,
  FaPython,
  FaReact,
  FaJava,
} from "react-icons/fa";
import {
  FaGolang,
} from "react-icons/fa6";
import { SiGraphql } from "react-icons/si";

import "../index.css";
import MySqlIcon from "./Icons/MySqlIcon";

const dark = "#6e9db0";
const light = "#3d7aed";

export default function Header({setOpen}) {

  function getLastMondayMonthAndYear() {
    const today = new Date();
    const day = today.getDay();
    const daysToLastMonday = (day + 6) % 7; // Calculate days to subtract to get last Monday

    // Get last Monday's date
    const lastMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - daysToLastMonday);

    const monthIndex = lastMonday.getMonth() ; // JavaScript months are 0-indexed
    const lastMondayYear = lastMonday.getFullYear();
    // Array of month abbreviations
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const lastMondayMonthAbbr = months[monthIndex];
    return { month: lastMondayMonthAbbr, year: lastMondayYear };
  }


  return (
    <>
      <Flex>
        <Container maxW="60ch">
          <Stack
            as={Box}
            spacing={{ base: 8, md: 14 }}
            py={{ base: 30, md: "5rem" }}
            direction="column"
          >
            <Box w="36" marginX="auto">
              <Img borderRadius="full" src="/outdoor_photo.jpeg" />
            </Box>
            <Box
              display="flex"
              backdropBlur="md"
              borderColor="black"
              background={useColorModeValue("gray.200", "whiteAlpha.100")}
              paddingX="6"
              paddingY="3"
              borderRadius="lg"
              margin="auto"
              justifyContent="center"
              boxSizing="unset"
            >
              <Heading
                fontWeight={400}
                fontSize={{ base: 20, md: 40 }}
                alignItems="center"
                lineHeight="110%"
                fontFamily="mono"
              >
                Hey, I'm Jacob <span className="wave"> 👋</span>
              </Heading>
            </Box>
            <Box alignItems="center">
              <Text
                fontWeight={300}
                fontFamily="mono"
                fontSize={{ md: "large" }}
                marginX="1"
                boxSizing="content-box"
              >
                <span className="highlighted-word">
                  professional software engineer
                </span>
                , <span className="highlighted-word">full-stack developer</span>{" "}
                and <span className="highlighted-word">tech enthusiast</span>.
              </Text>
            </Box>
            <Box justifyContent="center">
              <Badge
                as="a"
                variant="outline"
                marginBottom="5"
                fontSize="1.2rem"
              >
                Dev Stack
              </Badge>
              <Stack
                paddingX="14"
                justify="center"
                direction="row"
                wrap="wrap"
                fontSize="3.2rem"
                spacing="6"
              >
                <Tooltip label="ReactJS">
                  <span>
                    <Icon as={FaReact} color={useColorModeValue(light, dark)} />
                  </span>
                </Tooltip>
                <Tooltip label="NodeJS">
                  <span>
                    <Icon
                      as={FaNodeJs}
                      color={useColorModeValue(light, dark)}
                    />
                  </span>
                </Tooltip>

                <Tooltip label="JavaScript">
                  <span>
                    <Icon as={FaJs} color={useColorModeValue(light, dark)} />
                  </span>
                </Tooltip>
                <Tooltip label="HTML 5">
                  <span>
                    <Icon as={FaHtml5} color={useColorModeValue(light, dark)} />
                  </span>
                </Tooltip>
                <Tooltip label="CSS 3">
                  <span>
                    <Icon as={FaCss3} color={useColorModeValue(light, dark)} />
                  </span>
                </Tooltip>
                <Tooltip label="GraphQL">
                  <span>
                    <Icon
                      as={SiGraphql}
                      color={useColorModeValue(light, dark)}
                    />
                  </span>
                </Tooltip>
                <Tooltip label="Java">
                  <span>
                    <Icon as={FaJava} color={useColorModeValue(light, dark)} />
                  </span>
                </Tooltip>
                <Tooltip label="Python 3">
                  <span>
                    <Icon
                      as={FaPython}
                      color={useColorModeValue(light, dark)}
                    />
                  </span>
                </Tooltip>
                <Tooltip label="Golang">
                  <span>
                    <Icon
                        as={FaGolang}
                        color={useColorModeValue(light, dark)}
                    />
                  </span>
                </Tooltip>
                <Tooltip label="MySQL">
                  <span>
                    <Icon
                      as={MySqlIcon}
                      color={useColorModeValue(light, dark)}
                    />
                  </span>
                </Tooltip>
              </Stack>
              <Badge
                as="a"
                variant="outline"
                marginBottom="2"
                fontSize="1.2rem"
              >
                Contact
              </Badge>
              <Stack as={Box} marginY="5" spacing="5">
                <Text fontSize="2xl" fontWeight="thin">
                  <Link href="https://github.com/jbyerline" isExternal>
                    <Icon as={FiGithub} /> @jbyerline
                  </Link>
                </Text>
                <Text fontSize="2xl" fontWeight="thin">
                  <Link href="https://linkedin.com/in/jbyerline" isExternal>
                    <Icon as={FiLinkedin} /> @jbyerline
                  </Link>
                </Text>
              </Stack>
              <Text marginTop="6" fontFamily="mono">
                Get in touch <span>👉</span>{" "}
                <a className="mail" href="mailto:jbyerline@gmail.com">
                  jbyerline@gmail.com
                </a>
              </Text>
              <Badge
                as="a"
                variant="outline"
                marginTop="4"
                marginBottom="2"
                fontSize="1.2rem"
              >
                Projects
              </Badge>
              <Stack as={Box} marginY="4" spacing="4">
                <Text fontSize="2xl" fontWeight="thin">
                  <Link href="https://buddy-check.app" isExternal>
                    Buddy-Check
                  </Link>
                </Text>
                <Text fontSize="2xl" fontWeight="thin">
                  <Link href="https://badgereg.com" isExternal>
                    BadgeReg
                  </Link>
                </Text>
                <Text fontSize="2xl" fontWeight="thin">
                  <Link href="https://democollege.crew324.com" isExternal>
                    Merit Badge College
                  </Link>
                </Text>
                <Text fontSize="2xl" fontWeight="thin">
                  <Link href="https://broomball.crew324.com" isExternal>
                    Broomball
                  </Link>
                </Text>
                <Text fontSize="2xl" fontWeight="thin">
                  <Link
                    href="https://github.com/jbyerline/PolyPrint"
                    isExternal
                  >
                    PolyPrint
                  </Link>
                </Text>
                <Text fontSize="2xl" fontWeight="thin">
                  <Link href="https://poolservice.byerline.me/" isExternal>
                    Pool Automation
                  </Link>
                </Text>
                <Text fontSize="2xl" fontWeight="thin">
                  <Link
                    href="https://github.com/jbyerline/flask-purple-powerbase"
                    isExternal
                  >
                    Purple Powerbase Automation
                  </Link>
                </Text>
                <Text fontSize="2xl" fontWeight="thin">
                  <Link
                      href="https://unifi.byerline.me/"
                      isExternal
                  >
                    Unifi Product Scanner
                  </Link>
                </Text>
              </Stack>
              <Badge
                as="a"
                variant="outline"
                marginTop="4"
                marginBottom="4"
                fontSize="1.2rem"
              >
                Resume
              </Badge>
              <Text fontSize="2xl" fontWeight="thin">
                <Link
                    onClick={()=>{setOpen(true)}}
                >
                  View
                </Link>
              </Text>
              <Text fontSize="2xl" fontWeight="thin">
                <Link
                  href="/JacobByerlineResume.pdf"
                  download="Jacob Byerline's Resume"
                  isExternal
                >
                  Download
                </Link>
              </Text>
              <Text fontSize="l" fontWeight="thin" marginTop="4">
                v{window._env_.REACT_APP_VERSION}
              </Text>
              <Text fontSize="l" fontWeight="thin">
                Last Updated: {getLastMondayMonthAndYear().month}. {getLastMondayMonthAndYear().year}
              </Text>
            </Box>
          </Stack>
        </Container>
      </Flex>
    </>
  );
}
