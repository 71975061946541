import {Dialog, DialogActions, DialogContent, DialogTitle, Button} from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";

import '@react-pdf-viewer/core/lib/styles/index.css';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import "react-pdf/dist/cjs/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function PdfViewerDialog({ open, handleClose }) {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md">
            <DialogTitle>Jacob's Resume</DialogTitle>
            <DialogContent dividers>
                <Document
                    file={
                        "./JacobByerlineResume.pdf"
                    }
                >
                    <Page
                        renderAnnotationLayer={false}
                        pageNumber={1}
                    />
                </Document>
            </DialogContent>
            <DialogActions>
                <Button
                    component="a"
                    href="./JacobByerlineResume.pdf"
                    download="JacobByerlineResume.pdf"
                    color="primary"
                >
                    Download
                </Button>
                <Button onClick={handleClose} color="error">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PdfViewerDialog;